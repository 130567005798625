import { css } from '@emotion/react'

import { mTheme } from '../../style/themes'
import { ButtonAsLink } from '../../components/Button'

let keyImageSrc =
  'https://images.unsplash.com/photo-1525094246632-404f8f0a44b7?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'

let style = {
  hero: css({
    marginBottom: mTheme.size.S,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: mTheme.size.XS,
    [mTheme.breakpoint.small]: {
      flexDirection: 'row',
    },
  }),
  textContainer: css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: mTheme.size.XS,
    width: '100%',
    minWidth: 300,

    [mTheme.breakpoint.small]: {
      width: 'fit-content',
    },
  }),
  headline: css({
    width: 'fit-content',
    marginTop: mTheme.size.XS,
    marginBottom: mTheme.size.XXS,
    fontSize: mTheme.fontSize.XXL,

    [mTheme.breakpoint.large]: {
      fontSize: mTheme.fontSize.XXXL,
    },
  }),
  description: css({
    maxWidth: 300,
    textWrap: 'pretty',
    color: mTheme.fontColor.plain.secondary,
    margin: 0,
    marginBottom: mTheme.size.S,
  }),
  callToActionContainer: css({
    display: 'flex',
    gap: mTheme.size.XS,
    [mTheme.breakpoint.belowMedium]: {
      width: '100%',
      flexDirection: 'column',
    },
  }),
  primaryAction: css({
    // width: 'fit-content',
    // width: '100%',
  }),
  secondaryAction: css({
    color: mTheme.fontColor.plain.secondary,
    fontWeight: 'normal',
    opacity: 0.8,
  }),
  keyImage: css({
    maxWidth: '60%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: mTheme.radius.primary,
    background: `url(${keyImageSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center 30%',
    [mTheme.breakpoint.belowMedium]: {
      // border: '2px solid red',
      maxHeight: 215,
    },
  }),

  hideBelowSmall: css({
    display: 'none',
    [mTheme.breakpoint.small]: {
      display: 'block',
    },
  }),
}

export function Hero() {
  return (
    <section css={style.hero}>
      <div css={style.textContainer}>
        <h1 css={style.headline}>
          {/* <b>
          Find the live music <br /> you're missing
        </b> */}
          <b>
            Great music. <br /> Low fees.
          </b>
        </h1>

        {/* <p css={styles.description}>
        Where artists, venues and promoters collaborate, while fans create
        memories of a lifetime.
      </p> */}

        <p css={style.description}>
          Buying tickets made easy, so you can have the time of your life.
        </p>

        <div css={style.callToActionContainer}>
          <ButtonAsLink to="/signup" primary css={style.primaryAction}>
            Sign up
          </ButtonAsLink>

          {/* <ButtonAsAnchor
          rel="noopener noreferrer"
          target="_blank"
          css={styles.secondaryAction}
          href="https://discord.gg/2sTUFtcnP2"
        >
          <i className="fab fa-discord" /> Join community
        </ButtonAsAnchor> */}
        </div>
      </div>

      <div css={[style.keyImage, style.hideBelowSmall]} />
    </section>
  )
}

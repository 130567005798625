import { MetaFunction } from '@remix-run/react'

import { css } from '@emotion/react'

import { Hero } from './Hero'
import { mTheme } from '../../style/themes'
import { Card } from '../../components/Card/Card'
import { ButtonAsLink } from '../../components/Button'

let style = {
  container: css({
    display: 'grid',
    gap: mTheme.size.S,
  }),
  item: css({
    margin: '0 auto 0 auto',
    paddingLeft: mTheme.size.S,
    paddingRight: mTheme.size.S,

    width: '100vw',
    [mTheme.breakpoint.large]: {
      padding: 0,
      width: mTheme.pageWidth.L,
    },
  }),
  learnMore: css({
    fontWeight: 'normal',
    width: 'fit-content',
  }),
  heading: css({
    margin: 0,
  }),
  subtitle: css({
    margin: 0,
  }),
  image: css({
    maxWidth: 300,
  }),
  secondaryText: css({
    color: mTheme.fontColor.plain.secondary,
    width: '100%',
  }),
  secondaryContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: mTheme.size.S,

    [mTheme.breakpoint.medium]: {
      flexDirection: 'row',
    },
  }),
  cardWithImage: css({
    background: `linear-gradient(to right, black, 50%, transparent), url("https://images.unsplash.com/photo-1495582358586-280971a57d8b?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,

    backgroundPosition: 'right 20%',
    backgroundSize: 'cover',
    [mTheme.breakpoint.small]: {
      filter: 'grayscale(100%)',
    },
  }),
  fillerImage: css({
    borderRadius: mTheme.radius.primary,
    backgroundImage: `url("https://images.unsplash.com/photo-1450044804117-534ccd6e6a3a?q=80&w=420&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
    backgroundPosition: 'right 65%',
    maxWidth: '42.5%',
    filter: 'grayscale(100%)',
    backgroundSize: 'cover',
    width: '100%',
  }),
}

export let meta: MetaFunction = () => {
  return [
    { title: 'Yupty' },
    {
      property: 'og:title',
      content: 'Yupty',
    },
    {
      name: 'description',
      content: 'Great music, low fees.',
    },
    {
      name: 'og:description',
      content: 'Great music, low fees.',
    },
    // {
    //   name: 'description',
    //   content: "Find the live music you're missing.",
    // },
    // {
    //   name: 'og:description',
    //   content: "Find the live music you're missing.",
    // },
  ]
}

// Separate item wrapper so that the marquee can stretch full bleed
function Item({ children }: { children: React.ReactNode }) {
  return <div css={style.item}>{children}</div>
}

let LandingPage: React.FC = () => {
  return (
    <div css={style.container}>
      <Item>
        <Hero />
      </Item>

      <Item>
        <Card composeCss={style.cardWithImage}>
          <h2 css={style.heading}>
            Your next favourite band <br /> is a tap away
          </h2>
          <p css={[style.subtitle, style.secondaryText]}>
            Secure and easy ticket payments. No hidden fees.
          </p>
          <ButtonAsLink secondary to="/pricing" composeCss={style.learnMore}>
            Learn more
          </ButtonAsLink>
        </Card>
      </Item>
      <Item>
        <div css={style.secondaryContainer}>
          <Card>
            <h3 css={style.heading}>Discover</h3>
            <p css={[style.subtitle, style.secondaryText]}>
              Find the live music you're missing.
            </p>
            <ButtonAsLink secondary to="/latest" composeCss={style.learnMore}>
              Browse latest events
            </ButtonAsLink>
          </Card>
          <Card>
            <h3 css={style.heading}>Save events</h3>
            <p css={[style.subtitle, style.secondaryText]}>
              Keep track of future gigs.
            </p>
          </Card>
          <div css={style.fillerImage} />
        </div>
      </Item>
    </div>
  )
}

export default LandingPage

// TODO: lazy load these
import LandingPage from './LandingPage'
import LatestEventsPage from './LatestEventsPage'
import { useCurrentUser } from '../graphql/hooks'

let MainPage = () => {
  let { isAuthenticated } = useCurrentUser()

  if (!isAuthenticated) {
    return <LandingPage />
  }

  return <LatestEventsPage />
}

export default MainPage
